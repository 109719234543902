<template>
  <div>
    <!-- select 2 demo -->

    <div>
      <!-- Table Top -->
      <div>
        <!-- SideBar For Fee -->
        <b-sidebar
          id="sidebar-Fee"
          bg-variant="white"
          v-model="visibility1"
          sidebar-class="sidebar-lg"
          right
          no-header
          backdrop
          shadow
        >
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0">
              {{ sidebarTitle }}
            </h5>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hideDepSideBar()"
            />
          </div>
          <b-form class="p-2" @submit.prevent>
            <b-row class="mt-1">
              <b-col md="12">
                <b-form-group
                  label="Fee amount *"
                  invalid-feedback="amount is required."
                  ref="updatedamount"
                >
                  <b-input-group>
                    <the-mask
                      type="tel"
                      :mask="['############']"
                      id="phone"
                      v-model="myAmount"
                      class="form-control"
                      ref="updatedamount"
                      placeholder="fee amount"
                      @change="CheckUpdatedAmount()"
                    ></the-mask>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <!-- <b-col md="12">
                <b-form-group
                  label="Classes"
                  invalid-feedback="classes  is required."
                  ref="class"
                >
                  <v-select
                    ref="class"
                    multiple
                    :closeOnSelect="false"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    v-model="classID"
                    :options="classesOptions"
                    label="name"
                    :reduce="(option) => option.id"
                    placeholder="select classes"
                    @input="setClasses()"
                  />
                </b-form-group>
              </b-col> -->

              <b-col md="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="success"
                  class="mr-1 fullWidth"
                  @click="AmountUpdate()"
                  :disabled="request"
                  block
                >
                  <b-spinner v-if="request" small type="grow" />
                  <span v-if="request == false"> {{ sidebarButton }} </span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <sidebar-content />
        </b-sidebar>

        <!-- SideBar For Classes -->

        <b-sidebar
          id="sidebar-right"
          ref="mySidebar"
          bg-variant="white"
          v-model="visibility"
          sidebar-class="sidebar-lg"
          right
          no-header
          backdrop
          shadow
        >
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0">
              {{ sidebarTitle }}
            </h5>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hideSideBar()"
            />
          </div>
          <b-form class="p-2 pr-3" @submit.prevent>
            <b-row class="mt-1">
              <b-col md="12">
                <b-form-group
                  label="Fee Title *"
                  invalid-feedback="Fee title is required."
                  ref="title"
                >
                  <b-form-input
                    id="mc-first-name"
                    placeholder="Enter fee title"
                    v-validate="'required'"
                    name="classField"
                    ref="title"
                    v-model="myObj.feeType"
                    @focusout="CheckTitle()"
                  />
                </b-form-group>
              </b-col>

              <b-col md="12" v-if="!amountField">
                <b-form-group
                  label="Fee amount *"
                  invalid-feedback="amount  is required."
                  ref="amount"
                >
                  <b-input-group>
                    <the-mask
                      type="tel"
                      :mask="['############']"
                      id="phone"
                      v-model="mydefaultAmount"
                      class="form-control"
                      ref="amount"
                      placeholder="fee amount"
                      @change="CheckAmount()"
                      :disabled="amountField"
                    ></the-mask>
                  </b-input-group>
                  <p v-if="amountField" style="margin-top: 5px; color: gray">
                    Amount can be change from grid
                  </p>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Recurrence  *"
                  invalid-feedback="recurrence  is required."
                  ref="recurrence"
                >
                  <v-select
                    ref="recurrence"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="feeTypeOptions"
                    v-model="myObj.recurrence"
                    :reduce="(op) => op.value"
                    label="text"
                    placeholder="Add recurrence"
                    @change="CheckRecurrence()"
                  />
                </b-form-group>
              </b-col>

              <div
                v-if="subObj && subObj.valueBool"
                v-for="(item, ind) in subCategories"
                :key="ind"
                class="d-flex align-items-center flex-wrap mt-1"
              >
                <b-col md="5" class="pr-0">
                  <b-form-group
                    label="Title"
                    invalid-feedback="Title is required."
                    ref="sub_title"
                  >
                    <b-form-input
                      ref="sub_title"
                      placeholder="Enter title here"
                      v-model="item.feeType"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3" class="pr-0">
                  <b-form-group
                    label="Amount"
                    invalid-feedback="Amount is required."
                    ref="sub_amount"
                  >
                    <b-form-input
                      type="number"
                      placeholder="amount here"
                      ref="sub_amount"
                      v-model="item.defaultAmount"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3" class="pr-0">
                  <b-form-group
                    label="Late Fee"
                    invalid-feedback="Late Fee is required."
                    ref="sub_late"
                  >
                    <b-form-input
                      type="number"
                      placeholder="amount here"
                      ref="sub_late"
                      v-model="item.lateFee"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="1" class="pr-0">
                  <b-button
                    v-if="ind == subCategories.length - 1"
                    @click="AddDesc()"
                    variant="primary"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                  <b-button
                    v-if="subCategories.length > 1"
                    @click="removeDesc(item, ind)"
                    variant="outline-danger"
                    class="btn-icon rounded-circle mt-50"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-col>
              </div>

              <b-col md="12" class="mt-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="success"
                  class="mr-1 fullWidth"
                  @click="AddNew()"
                  :disabled="request"
                  block
                >
                  <!-- @click="Add()" -->
                  <b-spinner v-if="request" small type="grow" />
                  <span v-if="request == false"> {{ sidebarButton }} </span>
                </b-button>
              </b-col>
              <!-- {{ mydefaultAmount }} {{ myObj.defaultAmount }} -->
            </b-row>
          </b-form>
          <sidebar-content />
        </b-sidebar>

        <!-- fee increment -->
        <b-modal
          id="modal-inc"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          :title="'Fee Increment' + getFeeType()"
          no-close-on-backdrop
        >
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Classes"
                invalid-feedback="classes  is required."
                ref="class"
              >
                <v-select
                  ref="class"
                  multiple
                  :closeOnSelect="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="classID"
                  :options="classesOptions"
                  label="name"
                  :reduce="(option) => option.id"
                  placeholder="select classes"
                  @input="setClasses()"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Increment By"
                invalid-feedback="Increment By  is required."
                ref="incBy"
              >
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="incObj.incrementBy"
                  :options="incOptions"
                  label="text"
                  :reduce="(option) => option.value"
                  placeholder="select"
                  :clearable="false"
                  @input="checkValue()"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                :label="incObj.incrementBy"
                invalid-feedback="Invalid value entered"
                ref="incVal"
                label-class="text-capitalize"
              >
                <b-form-input
                  type="number"
                  placeholder="amount here"
                  ref="incVal"
                  v-model="incObj.value"
                  @input="checkValue()"
                />
              </b-form-group>
            </b-col>
            <b-col md="12" class="text-right">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                @click="applyInc()"
                :disabled="applying"
              >
                <b-spinner v-if="applying" small type="grow" />
                <span v-else>Apply</span>
              </b-button>
            </b-col>
          </b-row>
        </b-modal>

        <b-card>
          <b-row align-h="end" class="text-right">
            <b-col class="mt-xl-0 mt-lg-0 mt-md-0 mt-sm-1 mt-1" md="10">
              <!-- :class="{ 'my-collapsing adjust': !showAll }" -->
              <b-dropdown
                style="margin-inline: 2px; margin-bottom: 2px"
                v-for="(d, ind) in this.items"
                :key="ind"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                split
                :variant="current == d.id ? 'primary' : 'outline-primary'"
                :text="d.feeType"
                right
                @click="LoadGrid(d.id)"
              >
                <b-dropdown-item v-if="rights.edit" @click="EditFeeNew(d.id)">
                  <!-- @click="EditFee(d.id)" -->
                  Edit
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="rights.delete"
                  @click="DeleteFeeNew(d.id)"
                >
                  <!-- @click="DeleteFee(d.id)" -->
                  Delete
                </b-dropdown-item>

                <b-dropdown-divider />
              </b-dropdown>
              <!-- </div> -->
            </b-col>
            <b-col
              v-if="rights.add"
              class="mt-xl-0 mt-lg-0 mt-md-0 mt-sm-1 mt-1"
              style="margin-right: 8px"
              cols="*"
            >
              <b-button
                @click="AddOpen()"
                style=""
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon rounded-circle mr-50"
                v-b-tooltip.hover.top
                title="Add Fee"
              >
                <feather-icon size="18" icon="PlusIcon" />
              </b-button>

              <b-button
                @click="loadMissing()"
                style=""
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon rounded-circle mr-50"
                v-b-tooltip.hover.top
                title="Sync Data"
                :disabled="dataLoading"
              >
                <feather-icon size="18" icon="RefreshCwIcon" />
              </b-button>

              <b-button
                @click="openInc()"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover.top
                title="Fee Increment"
              >
                <feather-icon size="18" icon="TrendingUpIcon" />
              </b-button>

              <!-- <b-button
                @click="showAll = !showAll"
                style=""
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                class="btn-icon rounded-circle"
              >
                <feather-icon
                  v-if="!showAll"
                  class="cursor-pointer"
                  size="20"
                  icon="ArrowDownIcon"
                />
                <feather-icon
                  v-else
                  class="cursor-pointer"
                  icon="ArrowUpIcon"
                  size="20"
                />
              </b-button> -->
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col
              class="mt-lg-0 mt-md-0 mt-sm-1 mt-1"
              xl="12"
              lg="12"
              md="12"
              sm="12"
              cols="12"
            >
              <b-form-group class="">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>

                  <b-form-input v-model="searchQuery" placeholder="Search...">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- <b-col xl="2" lg="3" md="4" sm="12" cols="12">
              <b-button
                @click="loadMissing()"
                style="width: 100%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="dataLoading"
              >
                <span class="align-middle">Load Missing</span>
              </b-button>
            </b-col> -->
          </b-row>

          <b-table
            class="mt-1"
            ref="selectableTable"
            :items="filters"
            :fields="fields"
            :busy="dataLoading"
            :current-page="currentPage"
            :per-page="perPage"
            show-empty
            responsive
            hover
            :selectable="rights.edit"
            select-mode="single"
            @row-selected="EditAmount($event[0].obj)"
          >
            <template #empty="scope">
              <h3 class="mt-1 mb-1" style="text-align: center">
                No records found
              </h3>
            </template>
            <template #table-busy>
              <div
                class="d-flex justify-content-center mb-3"
                style="margin-top: 50px"
              >
                <b-spinner
                  style="width: 3rem; height: 3rem"
                  type="grow"
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
              </div>
            </template>
            <template #cell(cls)="data">
              <b-badge style="margin-inline: 2px" variant="light-primary">
                {{ data.item.cls }}
              </b-badge>
            </template>

            <template #cell(feeType)="data">
              <div>
                <b-badge style="margin-inline: 2px" variant="light-primary">
                  {{ data.item.feeType }}
                </b-badge>
              </div>
            </template>

            <template #cell(amount)="data">
              <b-badge style="margin-inline: 2px" variant="light-primary">
                {{ data.item.amount }}
              </b-badge>
            </template>

            <template #head(actions)="data">
              <div class="mr-5 text-center">
                <span>{{ data.label }}</span>
              </div>
            </template>

            <template #cell(actions)="data">
              <div class="text-center">
                <b-button
                  v-if="rights.edit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  v-b-tooltip.hover.left
                  title="Edit"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="EditAmount(data.item.obj)"
                >
                  <feather-icon icon="EditIcon" class="" />
                </b-button>
              </div>
            </template>
          </b-table>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { TheMask } from "vue-the-mask";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,
    BModal,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    TheMask,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
        };
      });
    });
    this.$store.commit("setRights", right);

    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      this.LoadData(0);
      this.loadSettings();
      this.LoadClasses();
    }
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    getColor(ind) {
      console.log("%%%%%% getting color", ind.index);
      return this.color[ind];
    },

    PageChange() {
      console.log(this.currentPage);
      return this.LoadData();
    },
    filters: function () {
      return this.gridData.filter((pro) => {
        return pro.cls.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      rights: {},
      showAll: false,
      myDepartments: [],
      request: false,
      colorDep: [],
      visibility1: false,
      feeTypeOptions: [
        { text: "None", value: "none" },
        { text: "default (Tuition Fees)", value: "default" },
        { text: "one time", value: "one time" },
        { text: "monthly", value: "monthly" },
        { text: "yearly", value: "yearly" },
        { text: "after due date", value: "after due date" },
      ],
      color: [
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
      ],

      sidebarTitle: "Add Class",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        { label: "Class", key: "cls" },
        { label: "feeType", key: "feeType" },
        { label: "amount", key: "amount" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      gridData: [],
      amountField: false,
      amountObj: {},
      myAmount: 0,
      mydefaultAmount: 0,
      selected: null,
      rangeDate: null,
      myObj: {
        id: 0,
        feeType: "",
        defaultAmount: 0,
        recurrence: "",
        campusID: this.$store.state.userData.cId,
      },
      assignedClass: null,
      classes: [],
      classesOptions: [],
      classID: [0],
      depObj: {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      },
      detailObj: {},
      timeout: 10000,
      current: 0,
      subObj: null,
      subCategories: [],
      incObj: {
        feeTypeID: 0,
        cls: [],
        incrementBy: "amount", // percentage
        value: 0,
      },
      incOptions: [
        { text: "Amount", value: "amount" },
        { text: "Percentage", value: "percentage" },
      ],
      applying: false,
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    getFeeType() {
      let obj = this.items.find((el) => el.id == this.current);
      return obj ? ` for ${obj.feeType}` : "";
    },
    openInc() {
      this.incObj = {
        feeTypeID: this.current,
        cls: [],
        incrementBy: "amount", // percentage
        value: 0,
      };
      this.classID = [0];
      this.$bvModal.show("modal-inc");
    },
    checkValue() {
      // console.log(this.incObj.value);
      var elem = this.$refs["incVal"];
      if (this.incObj.incrementBy == "amount") {
        if (this.incObj.value <= 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else if (this.incObj.value <= 0 || this.incObj.value > 100) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async applyInc() {
      if (this.checkValue() == true) {
        this.applying = true;
        this.incObj.feeTypeID = this.current;
        this.incObj.value = parseInt(this.incObj.value);
        if (this.classID[0] == [0]) {
          this.incObj.cls = [];
        } else this.incObj.cls = this.classID;
        // console.log(this.incObj);

        let status = await this.post({
          url:
            this.$store.state.domain +
            "Fees/IncrementFee?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.incObj,
          context: this,
          token: this.$store.state.userData.token,
          message: "Increment applied successfully.",
        });
        this.applying = false;
        if (status) {
          this.$bvModal.hide("modal-inc");
          this.LoadGrid(this.current);
        }
      }
    },

    async loadMissing() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "MyClasses/LoadMissingStructure?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      console.log(res);
      this.LoadGrid(this.current);
    },

    async loadSettings() {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: ["fee_sub_categories"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });
      this.subObj = res.find((el) => el.key == "fee_sub_categories");
      // console.log(this.subObj);
    },
    AddDesc() {
      this.subCategories.push({
        id: 0,
        feeType: "",
        defaultAmount: 0,
        recurrence: "",
        campusID: this.$store.state.userData.cId,
        parentID: 0,
        lateFee: 0,
      });
      // console.log(this.subCategories);
    },
    async removeDesc(item, ind) {
      if (item.id == 0) {
        this.subCategories.splice(ind, 1);
        // console.log(this.subCategories);
      } else {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "FeeType/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          grading: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.subCategories.splice(ind, 1);
        // console.log(this.subCategories);
      }
    },
    async EditFeeNew(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "FeeType/LoadSelected?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&feeTypeID=" +
          id,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      // console.log(res);
      this.myObj = res.feeType;
      this.subCategories = res.subCategories;
      if (this.subObj && this.subObj.valueBool) {
        if (this.subCategories.length == 0) {
          this.subCategories = [
            {
              id: 0,
              feeType: "",
              defaultAmount: 0,
              recurrence: "",
              campusID: this.$store.state.userData.cId,
              parentID: 0,
              lateFee: 0,
            },
          ];
        }
      } else this.subCategories = [];
      // console.log("fee edit", this.myObj);
      this.amountField = true;
      this.mydefaultAmount = this.myObj.defaultAmount;
      this.sidebarTitle = "Edit Fee";
      this.sidebarButton = "Update";
      this.visibility = true;
      var elem = this.$refs["recurrence"];
      elem.state = undefined;
      var elem = this.$refs["title"];
      elem.state = undefined;
      var elem = this.$refs["amount"];
      elem.state = undefined;
    },

    async EditFee(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "FeeType/GetSelected?id=" +
          id +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      this.myObj = await this.get(obj);
      // console.log("fee edit", this.myObj);
      this.amountField = true;
      this.mydefaultAmount = this.myObj.defaultAmount;
      this.sidebarTitle = "Edit Fee";
      this.sidebarButton = "Update";
      this.visibility = true;
      var elem = this.$refs["recurrence"];
      elem.state = undefined;
      var elem = this.$refs["title"];
      elem.state = undefined;
      var elem = this.$refs["amount"];
      elem.state = undefined;
    },

    async DeleteFee(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      // console.log(result);
      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "FeeType/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Fee removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData(0);
      }
    },

    async DeleteFeeNew(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      // console.log(result);
      if (result.isConfirmed) {
        var status = await this.post({
          url:
            this.$store.state.domain +
            "FeeType/Remove?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&feeTypeID=" +
            id,
          message: "Fee removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData(0);
      }
    },

    AddOpen() {
      this.mydefaultAmount = 0;
      this.myObj = {
        id: 0,
        feeType: "",
        defaultAmount: 0,
        recurrence: "",
        campusID: this.$store.state.userData.cId,
      };
      this.amountField = false;

      this.sidebarTitle = "Add Fee";
      this.sidebarButton = "Save";

      if (this.subObj && this.subObj.valueBool) {
        this.subCategories = [
          {
            id: 0,
            feeType: "",
            defaultAmount: 0,
            recurrence: "",
            campusID: this.$store.state.userData.cId,
            parentID: 0,
            lateFee: 0,
          },
        ];
      } else this.subCategories = [];

      this.visibility = true;
      var elem = this.$refs["recurrence"];
      elem.state = undefined;
      var elem = this.$refs["title"];
      elem.state = undefined;
      var elem = this.$refs["amount"];
      elem.state = undefined;
    },

    CheckClass() {
      console.log("class", this.assignedClass);
    },

    OpenDepartmentF() {
      this.visibility = true;
      //   this.depObj.id = 0;
      //   this.depObj.name = "";
      this.depObj.campusID = this.$store.state.userData.cId;
      this.sidebarTitle = "Add Fee";
      this.sidebarButton = "Save";
      //   var elem = this.$refs["depname"];
      //   elem.state = undefined;
    },

    EditAmount(obj) {
      // console.log("am_obj:", obj);
      this.amountObj = obj;
      this.myAmount = this.amountObj.amount;
      this.sidebarTitle = "Update Amount";
      this.sidebarButton = "Update";
      this.visibility1 = true;
      var elem = this.$refs["updatedamount"];
      elem.state = undefined;
    },

    CheckRequired(name) {
      var elem = this.$refs[name];
      if (this.myObj.name == "" || this.myObj.name > 20) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }

      // return (elem.state = this.$v.depObj.name.required);
    },

    CheckTitle() {
      var elem = this.$refs["title"];
      if (this.myObj.feeType == "" || this.myObj.feeType > 20) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAmount() {
      if (this.amountField) {
        return true;
      } else {
        var elem = this.$refs["amount"];
        if (this.mydefaultAmount < 0 || this.mydefaultAmount == null) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      }
    },
    CheckUpdatedAmount() {
      var elem = this.$refs["updatedamount"];
      if (this.myAmount < 0 || this.myAmount == null || this.myAmount == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckRecurrence() {
      var elem = this.$refs["recurrence"];
      if (this.myObj.recurrence == null || this.myObj.recurrence == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDepartment() {
      var elem = this.$refs["department"];
      if (this.myObj.departmentID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    hideSideBar() {
      this.visibility = false;
    },
    hideDepSideBar() {
      this.visibility1 = false;
    },

    Form() {
      this.$router.push("/apps/schoolinfo");
    },
    checkStatus() {
      if (this.myObj.Status == "") {
        return (this.errors.status = true);
      } else {
        return (this.errors.status = false);
      }
    },

    async LoadData(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "FeeType?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      // console.log("fee data", this.items);
      if (this.items.length > 0) {
        if (id == 0) {
          let feeTypeId = this.items[0].id;
          this.LoadGrid(feeTypeId);
        } else if (id == 1) {
          let feeTypeId = this.items[this.items.length - 1].id;
          this.current = feeTypeId;
          // this.LoadGrid(feeTypeId);
        } else {
          this.LoadGrid(id);
        }
      }
    },
    async LoadGrid(id) {
      this.current = id;
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "FeeType/Load?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&feeTypeID=" +
          id,
        token: this.$store.state.userData.token,
      };
      this.gridData = await this.get(obj);
      if (this.gridData.length != 0) {
        this.totalRows = this.gridData.length;
      }
      this.dataLoading = false;
      // console.log("grid", this.gridData);
    },
    async LoadDepartments() {
      var obj = {
        url:
          this.$store.state.domain +
          "departments?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.myDepartments = await this.get(obj);
    },

    async LoadClasses() {
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      //  this.items =[];
      let myitem = await this.get(obj);
      this.classes = myitem;
      this.classesOptions = [];
      this.classesOptions = this.classes.map((el) => el.cls);
      this.classesOptions.unshift({ id: 0, name: "All Classes" });
    },
    setClasses() {
      if (this.classID.at(-1) == 0) {
        this.classID = [0];
      } else {
        this.classID = this.classID.filter((el) => el != 0);
      }
      // console.log(this.classID);
    },

    async AmountUpdate() {
      // const data = {
      //   feeAmount: this.amountObj,
      //   clsIDs: this.classID,
      // };
      // console.log(data);
      this.CheckUpdatedAmount();
      if (this.CheckUpdatedAmount() == false) {
        return this.$bvToast.toast("Please enter the amount correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.amountObj.amount = parseInt(this.myAmount);
        // console.log(this.amountObj);
        this.request = true;
        var status = await this.put({
          url:
            this.$store.state.domain +
            "FeeAmount/" +
            this.amountObj.id +
            "?db=" +
            this.$store.state.userData.db,
          body: this.amountObj,
          message: "Amount updated successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        this.visibility1 = false;
        if (status) this.LoadGrid(this.amountObj.feeTypeID);
      }
    },

    async Add() {
      // console.log("obj:", this.myObj);
      // console.log("am", this.mydefaultAmount);
      this.CheckTitle();
      this.CheckAmount();
      this.CheckRecurrence();
      if (
        this.CheckTitle() == false ||
        this.CheckAmount() == false ||
        this.CheckRecurrence() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.myObj.defaultAmount = parseInt(this.mydefaultAmount);
        if (this.myObj.id == 0) {
          //Add
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "FeeType/Add?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Fee added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility = false;
          if (status) this.LoadData(1);
        } else {
          //Edit
          this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "FeeType/" +
              this.myObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Fee type updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility = false;
          if (status) this.LoadData(this.myObj.id);
        }
      }
    },
    checkSubCats() {
      let state = true;

      this.subCategories = this.subCategories.filter((el) => {
        return el.feeType != "" && el.defaultAmount != 0 && el.lateFee != 0;
      });
      this.subCategories.forEach((el) => {
        // console.log(el);
        el.defaultAmount = parseInt(el.defaultAmount);
        el.lateFee = parseInt(el.lateFee);
        if (
          isNaN(el.defaultAmount) ||
          el.defaultAmount <= 0 ||
          isNaN(el.lateFee) ||
          el.lateFee < 0 ||
          el.feeType === ""
        ) {
          state = false;
        }
      });
      return state;
    },
    async AddNew() {
      // console.log("obj:", this.myObj);
      // console.log("am", this.mydefaultAmount);
      this.CheckTitle();
      this.CheckAmount();
      this.CheckRecurrence();
      if (
        this.CheckTitle() == false ||
        this.CheckAmount() == false ||
        this.CheckRecurrence() == false ||
        this.checkSubCats() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.myObj.defaultAmount = parseInt(this.mydefaultAmount);
        if (this.myObj.id == 0) {
          //Add
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "FeeType/Save?db=" +
              this.$store.state.userData.db,
            body: {
              feeType: this.myObj,
              subCategories: this.subCategories,
            },
            message: "Fee added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility = false;
          this.subCategories = [];
          if (status) {
            this.LoadData(1);
            this.loadMissing();
          }
        } else {
          //Edit
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "FeeType/Save?db=" +
              this.$store.state.userData.db,
            body: {
              feeType: this.myObj,
              subCategories: this.subCategories,
            },
            message: "Fee type updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility = false;
          this.subCategories = [];
          if (status) this.LoadData(this.myObj.id);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.my-collapsing {
  height: 55px;
  overflow: hidden;
}
.my-collapsing.adjust {
  height: 39px;
}
.my-buttons {
  position: absolute;
  top: 0px;
  right: 10px;
  cursor: pointer;
}
</style>
